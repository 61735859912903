import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade"; // Import the CSS for the fade effect
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { HeadingVector, PlayIcon, PauseIcon, Star, SliderArrow } from "./Icons";
import profile from "../../components/images/svg/JoelG.svg";
import profile2 from "../../components/images/svg/LizH.svg"; 

const Testimonials = () => {
    
    // testimonial
    const testimonialList = [
    {
      posterImage: profile,
      rating: 5,
      image: profile,
      videoUrl:
        "https://d1873pk1gxetu0.cloudfront.net/landing/Recommendation.mov",
       //"https://www.youtube.com/embed/lOw1PXDgFoI?enablejsapi=1",
      userName: "Joel E-Com Store Owner",
      deceription:
        "I never knew how powerful UGC could be. The creators really understood our brand and delivered videos that truly resonated with their audiences.",
    },
    {
      posterImage: profile2,
      rating: 5,
      image: profile2,
      videoUrl:
        "https://d1873pk1gxetu0.cloudfront.net/landing/LizTestimonial2.mp4",
      userName: "Elizabeth E-Com Owner",
      deceription:
        "The platform’s built-in chat and negotiation tools made the entire process seamless. We’ve already lined up our next batch of UGC campaigns.",
    }
  ];




    const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
    const videoRefs = useRef([]);
    // const audioRefs = useRef([]); 
    // Function to play/pause video
    const toggleVideoPlay = (index) => {
      const video = videoRefs.current[index];
    //   const audio = audioRefs.current[index];
      if (video.paused) {
        video.play();
        // if(audio){
        //     audio.play(); 
        // }
      } else {
        video.pause();
        // if(audio){
        //     audio.pause(); 
        // }
      }
    };
    return (
      <>
        <div className=" pt-5 pb-4 py-md-5 position-relative" id="testimonials">
          <div className="orange_layer position-absolute"></div>
          <div className=" py-md-4 py-xl-5 text-center position-relative z-2">
            <div className=" position-relative">
              {" "}
              <HeadingVector className="position-absolute layer_text_bg d-inline-block" />
              <h2 className=" pb-1 mb-4 mb-lg-5 position-relative z-2 fw-bolder">
                Hear From E-Comm Businesses Like Yourself
              </h2>
            </div>{" "}
            <div className="container container_modified position-relative">
              <Swiper
                slidesPerView={1}
                spaceBetween={12}
                loop={true}
                fadeEffect={{ slideShadows: false }}
                effect="slide"
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-places-next",
                  prevEl: ".swiper-places-prev",
                }}
                breakpoints={{
                  992: {
                    slidesPerView: 1,
  
                    effect: "fade",
                  },
                  768: { slidesPerView: 1.8 },
                  576: { slidesPerView: 1.4 },
                  400: { spaceBetween: 20, slidesPerView: 1.25 },
                  360: {
                    spaceBetween: 20,
                    slidesPerView: 1.16,
                  },
                }}
                modules={[Pagination, Autoplay, Navigation, EffectFade]}
                className="mySwiper pb-lg-5 pb-4 ">
                {testimonialList.map((obj, index) => (
                  <SwiperSlide key={index}>
                    <div className="slider_Card h-100 d-flex flex-column flex-lg-row justify-content-between p-4 align-items-center mx-auto rounded-3">
                      {" "}
                      <div className="video_player position-relative overflow-hidden ">
                        {/* <span
                          className={`d-flex transition_03s justify-content-center align-items-center btn_play cursor_pointer z-1 position-absolute ${
                            playingVideoIndex === index
                              ? " opacity-0 visiblieIcon"
                              : ""
                          }`}
                          onClick={() => {
                            toggleVideoPlay(index);
                            setPlayingVideoIndex(
                              playingVideoIndex === index ? -1 : index
                            );
                          }}>
                          {playingVideoIndex === index ? (
                            <PauseIcon />
                          ) : (
                            <PlayIcon />
                          )}
                        </span> */}
                        { obj.videoUrl.split(".")[1]==="youtube"?    <iframe
                    id={`player-${index}`}
                    className="w-100 transition_03s object-fit-cover card_image"
                    src={`${obj.videoUrl}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>: 
                  <>
                  <span
                  className={`d-flex transition_03s justify-content-center align-items-center btn_play cursor_pointer z-1 position-absolute ${
                    playingVideoIndex === index
                      ? " opacity-0 visiblieIcon"
                      : ""
                  }`}
                  onClick={() => {
                    toggleVideoPlay(index);
                    setPlayingVideoIndex(
                      playingVideoIndex === index ? -1 : index
                    );
                  }}>
                  {playingVideoIndex === index ? (
                    <PauseIcon />
                  ) : (
                    <PlayIcon />
                  )}
                </span>
                        <video
                          poster={obj.posterImage}
                          src={obj.videoUrl}
                          className=" position-absolute h-100 w-100 object-fit-cover top-0 start-0"
                          ref={(el) => (videoRefs.current[index] = el)}>
                          {" "}
                        </video> </>}
                        {/* <audio
                            ref={(el) => (audioRefs.current[index] = el)}
                            src={obj.audioUrl}
                        /> */}
                      </div>{" "}
                      <div className=" d-flex flex-grow-1 justify-content-top flex-column">
                        <div className=" d-flex align-items-center gap-2">
                          <img
                            className=" img_user me-1"
                            src={obj.image}
                            alt="user-profile-content"
                          />
                          <div>
                            <div>
                              <h3 className="text-start mb-0">{obj.userName}</h3>
                              <div className=" d-flex gap-2 align-items-center">
                                {" "}
                                {[...Array(obj.rating)].map((_) => (
                                  <Star />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="text-start mb-0 mt-2 pt-1">
                          “{obj.deceription}”{" "}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <span className="overflow-hidden swiper-places-prev left_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center position-absolute z-1 cursor_pointer transition_03s">
                <SliderArrow />
              </span>
              <span className="overflow-hidden swiper-places-next right_btn slider_btn_parent d-none d-lg-flex justify-content-center align-items-center position-absolute z-1 cursor_pointer transition_03s">
                <SliderArrow />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default Testimonials;
  