import React, { useRef, useState } from "react";
import PrimaryButton from "../../components/PrimaryButton"; 
//import heroVideo from "../../assets/wanna-advertise.png";
import heroVideo from "../../components/images/png/LizFrame.png";
import videoPlay from "../../assets/play-btn.png"
import { PauseIcon, PlayIcon, HeadingVectorHome } from "./Icons";
import heroStar from "../../components/images/svg/star-hero.svg";
 
import CustomButton from "../../components/CustomButton"; 

const Hero = () => {
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);

  const RatingList = [
    {
      image: heroStar,
    },
    {
      image: heroStar,
    },
    {
      image: heroStar,
    },
    {
      image: heroStar,
    },
    {
      image: heroStar,
    },
  ];
  const videoRef = useRef(null);
  const toggleVideoPlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setPlayingVideoIndex(true);
    } else {
      video.pause();
      setPlayingVideoIndex(false);
    }
  };
  return (
    <>
      <div id="business-hero" className="position-relative">
        <div className="hero_top_shadow position-absolute rounded-pill end-0"></div>
        <div className="container container_modified">
          <h2 className="fw-bolder heading_hero text-black text-center mx-auto mb-2 pb-lg-1 position-relative z-1">
          A Free Platform That Helps You Connect with UGC Creators To Get Valuable Traffic to Your Products
            <span className="position-absolute start-50 translate-middle-x z-n1 hero_vector_business">
              <HeadingVectorHome />
            </span>
          </h2>
          <p className="hero_paragraph text-center text-black mx-auto mb-4">
            Post an Offer For Videos Our Trained UGC Influencers and Creators From TikTok
            will post videos on their page to help you get sales to your store. We help with 
            onboarding to TikTok as well for maximum benefits. 
          </p>
          <div className="d-flex justify-content-center">
            <span className="btn_get_started_today">
              <CustomButton
              text="See Viral Offers"
              btnStyle="fw-bold"
            />
            </span>
          </div>
          <div className="d-flex flex-column position-relative z-1 flex-sm-row align-items-center justify-content-center gap-sm-3 gap-1 pt-5 my-lg-4 mt-1 mb-4 pb-lg-2">
            <p className="font-lg text-black text-center mb-0">
              Rated 5/5 on Trustpilot{" "}
              <span className="fw-bold">(125 Reviews)</span>
            </p>
            <div className="d-flex gap-2">
              {RatingList.map((obj, index) => {
                return (
                  <span key={index}>
                    <img src={obj.image} alt="star" width={14} height={14} />
                  </span>
                );
              })}
            </div>
          </div>
          <div className="hero_video rounded-3 mx-auto position-relative z-1">
            <div className="hero_top_shadow hero_shadow_image position-absolute rounded-pill start-50 translate-middle-x z-n1"></div>
            <span
              className={`d-flex transition_03s cursor_pointer justify-content-center align-items-center btn_play z-1 position-absolute ${
                playingVideoIndex ? " opacity-0 visibleIcon" : ""
              }`}
              onClick={toggleVideoPlay}>
              {playingVideoIndex ? <PauseIcon /> : <PlayIcon />}
            </span>
            <video
              poster={heroVideo}
              src="https://d1873pk1gxetu0.cloudfront.net/landing/lizNocap.mp4"
              className="position-absolute h-100 rounded-3 w-100 object-fit-cover top-0 start-0"
              ref={videoRef}
              loop></video>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;