import PrimaryButton from "../../components/PrimaryButton";
import {
    HeadingVector,
    TimeLineImg,
    AlgorithmIcon,
    TrafficSource,
    MonitorIcon,
    PdfIcon,
    GraphIcon,
  } from "./Icons";


const OurProcess = () => {

    const processList = [
        {
          icon: <AlgorithmIcon className="icon_1" />,
          title: "Post Your Brand Deal With One-Click.",
        },
        {
          icon: <TrafficSource className="icon_2" />,
          title: "Our network of vetted UGC creators reviews your offer.",
        },
        {
          icon: <MonitorIcon className="icon_3" />,
          title: "Discuss content formats, timelines, and compensation with creator.",
        },
        {
          icon: <PdfIcon className="icon_4" />,
          title: "Your chosen creator films, edits, and posts the video to their TikTok feed.",
        },
        {
          icon: <GraphIcon className="icon_5" />,
          title: "Monitor engagement, clicks, and sales through our real-time dashboard.",
        },
      ];

    return (
      <div id="our-process" className="py-5 bg_gradient ps-3 ps-xl-0">
        <div className=" container_modified py-md-4 py-xl-5 my-sm-1">
          <div className=" position-relative">
            {" "}
            <HeadingVector className="position-absolute layer_text_bg d-inline-block" />
            <h2 className=" text-white text-center fw-bolder mb-0 position-relative z-2">
              Our Process for Brands
            </h2>
          </div>{" "}
          <div
            id="timeline"
            className=" mt-5 d-flex flex-column position-relative">
            <TimeLineImg className="  position-absolute timeline_img d-lg-block d-none" />
            {processList.map((obj, i) => (
              <div
                key={i}
                className={`timeline_box d-flex gap-3 position-relative bg-white align-items-center  
                ${
                  i === 0
                    ? " mx-auto justify-content-md-center  me-md-auto ms-md-0 timeline_box_1"
                    : i === 1
                    ? " mx-auto justify-content-md-center  me-md-0  mt-md-5 mt_custom_cards mt-lg-2 ms-md-auto timeline_box_2"
                    : i === 2
                    ? " mx-auto justify-content-md-center me-md-auto ms-md-0 mt-md-5 mt_custom_cards mt_timeline_box_three timeline_box_3"
                    : i === 3
                    ? " mx-auto justify-content-md-center  me-md-0  mt-md-5 mt_custom_cards ms-md-auto mt-lg-4 me-lg-4 timeline_box_4"
                    : i === 4
                    ? " mx-auto me-md-auto ms-md-0  mt-md-5 mt_custom_cards timeline_box_5 justify-content-md-center justify-content-start"
                    : ""
                }`}>
                <div className="tag_count position-absolute d-flex align-items-center justify-content-center">
                  <p className="mb-0 text-white">{i + 1}</p>
                </div>
                <span className=" d-inline-block me-1 ">{obj.icon}</span>
                <h3 className={` mb-0 ${i === 0 ? "me-2" : ""}`}>{obj.title}</h3>
              </div>
            ))}
          </div>
          <div>
          <PrimaryButton
                      text="Get Started Today!"
                      btnStyle="btn__  d-block mx-auto mt-4 mt-md-5"
                      target="_blank"
                      url="https://incentivemeet.com/signup"
                    />
          </div>
        </div>
      </div>
    );
  };
  
  export default OurProcess;