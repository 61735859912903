import React, {useState, useEffect} from 'react';
import { Col,Container, Form, Row, Button, Tooltip, OverlayTrigger, Alert, Image } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import {Link, useNavigate} from 'react-router-dom';
import "./login.scss";
import logo from '../../assets/logo.svg';
//import axios from 'axios';
import axios from '../../backendClient'
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure, loginStart, loginSuccess, clearError} from '../../redux/userSlice';

const Login = ()=>  {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState(''); 
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading, error} = useSelector((state)=> state.user);
  const [show, setShow] = useState(true);
  const [type, setType] = useState(''); 
  const [message, setMessage] = useState('');
  const [resendError, setResendError] = useState('');  



  useEffect(() => {
    setShow(false); 
    dispatch(clearError());
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
        //let prefix = '';
        //phone.startsWith('+') ? prefix = '+' : prefix = '';

        // Validate the email
        // const emailRegex = '/^[^\s@]+@[^\s@]+\.[^\s@]+$/';
        // if (!emailRegex.test(email)) {
        //     alert("Invalid email address");
        //     return; // Exit if the email is invalid
        //  }


        // const res = await axios.put('/api/auth/login', {phone:prefix+phone.replace(/\D/g, "")}, {
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // });

        const res = await axios.put('/api/auth/login', { email }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });



        if(res){
          dispatch(loginStart());
          setMessage(res.data.message); 
          setType(res.data.type); 
          setShow(true); 
          setStep(2);
        }
    } 
    catch(err) {
      console.error(err);
      dispatch(loginFailure(err.response.data.message));
      setShow(true);
  }

  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to the server-side route that handles file upload
      const res = await axios.post('/api/auth/confirmlogin', {email, code:code}, {
          headers: {
              'Content-Type': 'application/json'
          }
      });

      if(res.data){
          //check if cookies are turned off etc.
          //set them via the client in production
         //storageAccess(res.data);

          // Handle final submission here
         //navigate to Search Page
        dispatch(loginSuccess(res.data));
        navigate("/eventlisting");
      }
  } catch(err) {
      console.error(err);
      dispatch(loginFailure(err.response.data.message));
      setShow(true);
  }
    // handle final submission here
  };  

  const handleResendCode = async() => {
    // Your code to resend the code goes here
    try {
      // Make a POST request to the server-side route that handles file upload
      //This has to be with email lookup now but only for non-business users 
      await axios.post('/api/auth/resendcode', {email , code:code});

  } catch(err) {
      console.error(err);
      setResendError('Unable to send code via SMS to business account.'); 
      setShow(true);
  }

  };
  return (
    <Container className='main-container'>
      <Row>
      {loading && show && <Alert variant="success" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{message}</Alert>}
      {error && show && <Alert variant="danger" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{error}</Alert>}
      {resendError && show && <Alert variant="danger" onClose={() => {setShow(false); dispatch(clearError());}} dismissible>{resendError}</Alert>}
      </Row>
        <Row>
        <Col md={6} className="login__bg order-2 order-md-1">
        </Col>
        <Col md={6} className="form-box d-flex align-items-center justify-content-center flex-direction-column order-1 order-md-2">
        
        <h2 className="form-title"><Image src={logo} style={{width: 75, height:75, objectFit:'cover', borderRadius:'50%'}}/></h2>
        <h4 className="my-3">Log In</h4>
          <CSSTransition
            in={step === 1}
            timeout={300}
            classNames="step"
            unmountOnExit
          >
            <Form onSubmit={handleEmailSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">Account Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Account Email"
                />
                <Form.Text className="text-muted">
                   We will send an SMS code to your phone or email. No passwords!
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
              <div className="py-4 mx-4">
                <p className='text-center'>No account? <br/> <Link to="/signup">Password-Free Sign Up</Link></p>
              </div>
            </Form>
          </CSSTransition>

          <CSSTransition
            in={step === 2}
            timeout={300}
            classNames="step"
            unmountOnExit
          >
            <Form onSubmit={handleCodeSubmit}>
              <Form.Group className="mb-3">
                <Form.Label className="form-label">{type === 'Business'? 'Email Code' : 'Account SMS Code'}</Form.Label>
                <OverlayTrigger
                    placement="auto"
                    overlay={
                      <Tooltip id="tooltip-auto">
                        {type === 'Business' ? 'Enter the Code sent to your account email.'  :  
                        'Enter the 5 digit Code sent to the phone number associated with the account.'}
                      </Tooltip>
                    }>
                <Form.Control
                  type="text"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                  placeholder="Enter your code"
                /></OverlayTrigger>
                                <Form.Text className="text-muted">
                    {type === 'Business' ? 'Check your email for a code.' : 'Check your phone including spam for an SMS code.'}

                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Verify
              </Button>
              <div className="py-4 mx-4">
                <p className='text-center'>No Account? <br /> <Link to="/signup">Password-Free Sign Up</Link></p>
                <p className='text-center link-style' onClick={handleResendCode}>Resend Code via SMS Text</p>
              </div>
            </Form>
          </CSSTransition>
          

        </Col>
        </Row>
    </Container>

  )
}

export default Login
