import React from 'react'
import {Accordion, Container, Row, Col} from 'react-bootstrap';
import './faq.scss';

const Faq = () => {
    return (
        <Container className="pt-5">
            <Row>
                <Col md={7} className="faq__bg order-2 order-md-1"></Col>
                <Col md={5} className="align-items-center justify-content-center">
                    <h2 className="form-title text-center mb-4">Frequently Asked Questions/ How it Works</h2>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What the hell is this website and how do I use it? </Accordion.Header>
                            <Accordion.Body>
                            IncentiveMeet.com is a platform that connects brands with talented TikTok creators who 
                            deliver authentic, engaging content to their audiences. 
                            Whether you're looking for influencer collaborations or UGC (User-Generated Content), 
                            our platform makes it easy to find and collaborate with the right creators for your campaigns.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>How does it work?</Accordion.Header>
                            <Accordion.Body>
                            Brands: Sign in with your shop domain to browse creators, view their metrics, and initiate collaborations.
                            <br/>
                            Creators: Create a profile, self-report your metrics, and connect with brands to start working on campaigns.
                            <br/>
                            Collaborate: Discuss deliverables and terms directly on our platform to bring your campaign to life.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>How do I sign in as a brand?</Accordion.Header>
                            <Accordion.Body>
                            Visit IncentiveMeet.com and enter your shop domain to get started. 
                            Follow the prompts to create an account, verify your brand, and access the creator database.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How do I create an account as a creator?</Accordion.Header>
                            <Accordion.Body>
                            Visit IncentiveMeet.com, click “Sign Up as a Creator,” and follow the steps to create your profile. 
                            Be sure to include your TikTok username, niche, self-reported metrics, and links to sample content.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>How do I get paid? </Accordion.Header>
                            <Accordion.Body>
                            After you’ve completed a campaign, the brand will pay you directly using the payment method you agree upon. 
                            We recommend using secure methods like PayPal or Venmo for transactions.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Can I request additional verification from creators or changes?</Accordion.Header>
                            <Accordion.Body>
                            Yes, creators provide self-reported metrics, but you can request screenshots 
                            or other documentation to verify their follower count, engagement rate, and average views.
                            Creators and Brands can agree upon change requests beforehand. Brands should only pay creators
                            once the deliverable is delivered with a downloadable file and posted on the account. 
                            You can get us involved but all deals are private deals between the creator and brand. 
                            Creators can review brands and let us know if a brand payment wasn't made so we can take action. 
                            Brands can also let us know if a creator didn't deliver. Brands should not pay creators without first
                            getting delivery and a link that the video was posted. 
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            </Col>
            </Row>
        </Container>
        
        
      );
}

export default Faq