import PrimaryDropdown from "./PrimaryDropdown";
import { HeadingVectorHome } from "./Icons";

const ProjectSales = () => {
    const OfferDropItemsWho = ["a Shopify Brand", "an Affiliate Looking to Make Money Online"];
    const OfferDropItemsWhat = ["Promote My Brand", "Increase Sales", "Make Income with Traffic", "Health Products", "Fitness Supplements"]; 
    const OfferDropItemsResults = ["1-4", "4-10", "11+"]; 
  return (
    <>
      <div id="more-customer" className="position-relative z-1">
        <div className="position-absolute start-0 customer_left_shadow rounded-pill z-n1"></div>
        <div className="position-absolute end-0 customer_left_shadow rounded-pill z-n1"></div>
        <div className="container container_modified">
          <h4 className="fw-bolder text-black text-center mb-lg-3 mb-2 heading_customer mx-auto position-relative z-1">
          Curious about the potential return on partnering with TikTok creators?
            <span className="position-absolute start-50 translate-middle-x z-n1 customer_heading_vector">
              <HeadingVectorHome />
            </span>
          </h4>
          <p className=" text-black font-lg text-center position-relative z-1 mb-4 pb-lg-3">
            <span className="fw-bold"> Use our quick earnings calculator to estimate the additional revenue, conversions, and audience growth that these authentic video endorsements can bring your store.</span>
          </p>
          <div className="bg-white customer_card">
            <div className="text-black font-lg d-flex flex-column flex-lg-row justify-content-center align-items-lg-center gap-lg-4 gap-2">
              <span className="d-flex select_list justify-content-between justify-content-lg-start align-items-center gap-lg-4 gap-3">
                I’m
                <PrimaryDropdown
                  customStyles="customer_select"
                  title="Select"
                  itemList={OfferDropItemsWho}
                />
              </span>
              <span className="d-flex select_list justify-content-between justify-content-lg-start align-items-center white-nowrap gap-lg-4 gap-3">
                and in the
                <PrimaryDropdown
                  customStyles="customer_select"
                  title="Category"
                  itemList={OfferDropItemsWhat}
                />
              </span>
              <span className="d-flex select_list justify-content-between justify-content-lg-start align-items-center gap-lg-4 gap-3">
                space with just:
                <PrimaryDropdown
                  customStyles="customer_select"
                  title="Results"
                  itemList={OfferDropItemsResults}
                />
              </span>
            </div>
            <p className="text-black font-lg text-lg-center mt-lg-3 mt-2 mb-0">
            sales will see $5000-$20000 in additional income per week based on overall GMV (Gross Market Value Sales)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSales;