import { useState } from "react";
import googleAds from "../../components/images/webp/google-ads.webp"; 
import phoneIcon from "../../components/images/svg/phone-icon.svg"; 
import emailIcon from "../../components/images/svg/email-icon.svg"; 
import PrimaryButton from "../../components/PrimaryButton"; 
import downloadIcon from "../../components/images/svg/download-icon.svg"; 
import { HeadingVector } from "./Icons";
import axios from '../../backendClient'; 


const BusinessForm = () => {

    const [phone, setPhone] = useState(''); 
    const [email, setEmail] = useState(''); 
    const [infoSubmit, setInfoSubmit] = useState(false); 

    const handleInputChange = (e) => {
        const {name, value} = e.target; 
        if(name === 'phone'){
            setPhone(value); 
        }else if (name === 'email'){
            setEmail(value); 
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (phone && email) {
          try {
            // Save data to your backend if needed
            // Post data to backend endpoint
            //const response = await axios.post('/storeLeads', { phone, email });
            // handle response data if needed
            //console.log(response.data);
    
            setInfoSubmit(true);
          } catch (error) {
            console.error('Error:', error);
          }
        }
      };

    return (
      <>
        <div id="business-form" className="position-relative z-1">
          <div className="position-absolute end-0 z-n1 business_form_shadow rounded-pill business_top_shadow"></div>
          <div className="position-absolute start-0 z-n1 business_form_shadow rounded-pill business_bottom_shadow"></div>
          <div className="container container_modified">
            <h5 className="form_heading fw-bolder text-center z-1 position-relative mb-3 text-black mx-auto">
              Free Guide To UGC Growth For Brands
              <span className="position-absolute start-50 translate-middle-x z-n1 form_heading_vector">
                <HeadingVector />
              </span>
            </h5>
            <p className="text-center font-md text-black mb-lg-5 mb-4 form_paragraph mx-auto">
             Learn How To Get Maximum Benefits with How TikTok Works And How You Can Use It To 
             Take Your Store to Millions of Dollars Per Month
            </p>
            <div className="row strategy_card align-items-center bg-white">
              <div className="col-md-6 col-12 px-0 text-center text-md-start">
                <div className="strategy_win_image mx-auto mx-md-0 overflow-hidden rounded-3">
                  <img
                    src={googleAds}
                    alt="strategy-win"
                    className="rounded-3 w-100 transition_03s"
                  />
                </div>
              </div>
              <div className="col-md-6 col-12 mt-3 mt-md-0 pt-1 pt-md-0 px-0">
                <div className="form_card">
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="phone" className="text-black font-sm">
                      Phone
                    </label>{" "}
                    <br />
                    <div className="form_input bg-white d-flex align-items-center gap-1 mt-2">
                      <img
                        src={phoneIcon}
                        alt="phone-icon"
                        width={19}
                        height={19}
                      />
                      <input
                        name="phone"
                        value={phone}
                        onChange={handleInputChange}
                        placeholder="Your phone"
                        className="border-0 bg-transparent w-100 font-md"
                      />
                    </div>
                    <label
                      htmlFor="phone"
                      className="text-black font-sm mt-lg-4 mt-3"
                    >
                      Email
                    </label>
                    <br />
                    <div className="form_input bg-white d-flex align-items-center gap-1 mt-2">
                      <img
                        src={emailIcon}
                        alt="phone-icon"
                        width={19}
                        height={14}
                      />
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        placeholder="Your email"
                        className="border-0 bg-transparent w-100 font-md"
                      />
                    </div>
                    <div className="btn_download mt-lg-4 mt-3 mb-lg-3">
                    {!infoSubmit && (
                                     <PrimaryButton
                                     text="Submit"
                                     btnStyle="fw-bold w-100 d-inline-block"
                                   />
                    )}
                    {infoSubmit && (
                      <PrimaryButton
                        text="Thanks! Download Your Book"
                        btnStyle="fw-bold w-100 d-inline-block"
                        target="_blank"
                        url="https://incentiveweb.s3.amazonaws.com/book/_+3+Google+Ads++Success+For+Business+Owners+By+IncentiveMeet.pdf"
                        icon={
                          <span className="ps-2">
                            <img
                              src={downloadIcon}
                              alt="icon"
                              width={15}
                              height={18}
                            />
                          </span>
                        }
                      />
                    )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default BusinessForm;
  